<template>
    <div class="row">

      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
              <h5 class="title">Providers</h5>
          </div>
          <div class="card-body table-responsive table-full-width">
            <el-table :data="providerss">
                <el-table-column label="Name" property="name"></el-table-column>
            </el-table>

          </div>
        </div>
      </div>

    </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  Vue.use(Table)
  Vue.use(TableColumn)
  import { API } from 'aws-amplify';
  //import { listProviderss } from '../../../graphql/queries';
  //import { listProviderss } from '../../../graphql/subscriptions';
  export default {
    async created() {
      this.listProviderss()
    },
    data () {
      return {
        providerss: []
      }
    },
    methods: {
      async listProviderss() {
        //const resp = await API.graphql({
          //query: listProviderss
        //});
        //this.providerss = resp.data.listProviderss.items;
      }
    }
  }

</script>
<style>

</style>
