<template>
    <div class="row">

      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
              <h5 class="title">Customers</h5>
          </div>
          <div class="card-body table-responsive table-full-width">
            <el-table :data="consumerss">
                <el-table-column label="Name" property="name"></el-table-column>
                <el-table-column label="Spread" property="spread"></el-table-column>
            </el-table>

          </div>
        </div>
      </div>

    </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  Vue.use(Table)
  Vue.use(TableColumn)
  import { API } from 'aws-amplify';
  //import { listConsumerss } from '../../../graphql/queries';
  //import { listConsumerss } from '../../../graphql/subscriptions';
  export default {
    async created() {
      this.listConsumerss()
    },
    data () {
      return {
        consumerss: []
      }
    },
    methods: {
      async listConsumerss() {
        //const resp = await API.graphql({
          //query: listConsumerss
        //});
        //this.consumerss = resp.data.listConsumerss.items;
      }
    }
  }

</script>
<style>

</style>
